<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-16 17:58:21
 * @LastEditTime: 2019-10-18 15:17:23
 * @LastEditors: Please set LastEditors
 -->
<template>
  <el-button
    :type="type"
    size="mini"
    :disabled="!hasPerms(perm)"
    @click="handleClick"
    plain
  >{{label}}</el-button>
</template>

<script>
import { hasPermission } from "../../permission/index.js";
export default {
  name: "KtButton",
  props: {
    label: {
      type: String,
      default: "Button"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    perm: {
      type: Number,
      default: ""
    },
    type: {
      type: String,
      default: "primary"
    }
  },
  data() {
    return {};
  },
  methods: {
    handleClick: function() {
      
      // 按钮操作处理函数
      this.$emit("click", {});
    },
    hasPerms: function(perm) {
      // console.log("btn==>", hasPermission(perm) && !this.disabled);
      return hasPermission(perm) && !this.disabled;
    }
  },
  mounted() {}
};
</script>

<style lang="scss">
@import "../../styles/app";
</style>