<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-27 17:01:13
 * @LastEditTime: 2019-11-09 16:41:44
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="favorite-container">
    <page-top :title="$t('supplier_supplier_goodsAttribute.49D281@commodity_property')">
      <span class="fa fa-bookmark fa-2x"></span>
    </page-top>
    <el-card>
      <section style="display:flex;justify-content:flex-end;">
        <KtButton
          :label="$t('public.DC9752@btn_add')"
          class="table-btn btn-gray"
          :perm="746"
          @click="add"
        ></KtButton>
      </section>
      <el-table
        :data="tableData"
        :row-class-name="$tableRowClassName"
        style="width: 100%;margin-top:20px;"
        v-loading="loading"
        :element-loading-text="$t('public.4ABAB1@loading')"
        :header-cell-style="{'text-align':'center',}"
        :cell-style="{'text-align': 'center',}"
      >
        <el-table-column prop="spec_id" :label="$t('aboutus.A94527@spec_id')"></el-table-column>
        <el-table-column
          prop="spec_name"
          :label="$t('supplier_supplier_goodsAttribute.E416AD@attribute_name')"
        ></el-table-column>
        <el-table-column
          prop="spec_value"
          :label="$t('supplier_supplier_goodsAttribute.D0456F@attribute_value')"
        ></el-table-column>
        <el-table-column prop="create_time" :label="$t('public.D17BCA@created_time')"></el-table-column>
        <el-table-column :label="$t('public.03EA09@operation')">
          <template slot-scope="scope">
            <KtButton
              :label="$t('public.B6D57F@btn_modify')"
              type="info"
              :perm="4786"
              @click="modify(scope.row)"
            ></KtButton>
            <KtButton
              :label="$t('public.1FBB5E@btn_delete')"
              type="danger"
              :perm="7642"
              @click="deleteUser(scope.row)"
            ></KtButton>
          </template>
        </el-table-column>
      </el-table>

      <section style="display:flex;justify-content:flex-end;">
        <el-pagination
          style="padding-top:20px;"
          @size-change="handleSizeChange"
          :page-sizes="[5,10,15]"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </section>
    </el-card>
    <el-dialog
      :title="addModify==0?$t('public.DC9752@btn_add'):$t('public.B6D57F@btn_modify')"
      width="50%"
      :visible.sync="dialogVisible"
    >
      <el-row>
        <el-col :span="20" :offset="2">
          <el-form :model="dialogForm" :rules="rules" ref="form" label-width="140px">
            <el-form-item
              :label="$t('supplier_supplier_goodsAttribute.3FB998@Attribute_name_hk')"
              prop="spec_name_hk"
            >
              <el-input v-model="dialogForm.spec_name_hk"></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('supplier_supplier_goodsAttribute.809D25@Attribute_name_en')"
              prop="spec_name_en"
            >
              <el-input v-model="dialogForm.spec_name_en"></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('supplier_supplier_goodsAttribute.D0456F@attribute_value')"
              prop="spec_value"
            >
              <el-input
                v-model="dialogForm.spec_value"
                :placeholder="$t('restaurant_supplier_attr.DBE743@more_val')"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('supplier-produceManage-publishProduct.EE6D61@Sort')"
              prop="sort"
            >
              <el-input
                type="number"
                @input.native="$inputLenght"
                v-model="dialogForm.sort"
                :placeholder="$t('restaurant_supplier_attr.B6A194@default')"
              ></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">{{$t('public.836FD4@cancel')}}</el-button>
        <el-button
          size="small"
          type="primary"
          @click="comfirmDialog('form')"
        >{{$t('public.3B45A2@confirm')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import pageTop from "../../components/PageTop/PageTop";
import KtButton from "../../components/ktButton/KtButton";

export default {
  data () {
    return {
      addModify: 0,
      dialogVisible: false,
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      dialogForm: {
        spec_id: "",
        spec_name_en: "",
        // spec_name_cn: "",
        spec_name_hk: "",
        spec_value: "",
        sort: ""
      },
      rules: {
        spec_name_en: { required: true, message: this.$t('aboutus.B003A6@This_is_required'), trigger: 'blur' },
        spec_name_hk: { required: true, message: this.$t('aboutus.B003A6@This_is_required'), trigger: 'blur' },
        spec_value: { required: true, message: this.$t('aboutus.B003A6@This_is_required'), trigger: 'change' }
      },
      loading: false
    };
  },
  components: {
    pageTop,
    KtButton
  },
  created () {
    this.getTableData();
  },
  methods: {
    toAdd (supplier_id) { },
    // 获取列表数据
    getTableData () {
      this.loading = true;
      this.$http
        .getGoodsSpecList({
          size: this.pageSize,
          page: this.currentPage
        })
        .then(res => {
          this.loading = false;
          this.tableData = res.data.list;
          this.total = res.data.sum;
        });
    },
    // 刪除
    deleteUser (row) {
      this.$confirm(
        this.$t("shop-goods.2ECFD0@Confirm_delete"),
        this.$t("public.553159@tips"),
        {
          confirmButtonText: this.$t("public.3B45A2@confirm"),
          cancelButtonText: this.$t("public.836FD4@cancel")
        }
      ).then(() => {
        this.$http
          .delGoodsSpec({
            spec_id: row.spec_id
          })
          .then(res => {
            if (this.tableData.length == 1 && this.currentPage > 1) {
              this.currentPage -= 1;
            }
            this.getTableData();
          });
      });
    },
    add () {
      this.addModify = 0;
      this.dialogVisible = true;
      // if (this.dialogForm.spec_name_hk) {
      // }
      this.$nextTick(() => {
        this.$refs["form"].resetFields();
      })
      this.dialogForm.spec_id = "";
    },
    // 修改时获取信息
    modify (row) {
      this.addModify = 1;
      this.row = row;
      this.$http
        .getGoodsSpecInfo({
          spec_id: row.spec_id
        })
        .then(res => {
          this.dialogVisible = true;
          this.$nextTick(() => {
            this.dialogForm = res.data;
          });
        });
      this.$nextTick(() => {
        this.$refs["form"].resetFields();
      })
    },
    // 点击确认订单
    comfirmDialog (formName) {
      let params = Object.assign({}, this.dialogForm);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.addModify) {
            // 修改
            this.$http.updateGoodsSpec(params).then(res => {
              this.dialogVisible = false;
              this.getTableData();
            });
          } else {
            // 新增
            this.$http.addGoodsSpec(params).then(res => {
              this.dialogVisible = false;
              this.getTableData();
            });
          }
        } else {
          return false;
        }
      });

    },
    handleSizeChange (val) {
      this.pageSize = val;
      this.getTableData();
    },
    handleCurrentChange (val) {
      this.currentPage = val;
      this.getTableData();
    }
  }
};
</script>
<style lang="scss" scoped>
.form-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pagination-container {
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  .pagination {
    float: right;
  }
}
.tabs {
  width: 170px;
  margin-bottom: -20px;
  > ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    li {
      text-align: center;
      width: 40px;
      font-size: 14px;
      line-height: 50px;
      color: #333333;
      cursor: pointer;
      transition: all 0.3s;
      box-sizing: border-box;
      border-bottom: 2px solid transparent;
    }
  }
}
.active {
  font-weight: 400;
  // border-bottom: 2px solid #333333;
  border-color: #333333 !important;
}
</style>