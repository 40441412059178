<template>
  <div class="page-top">
    <div class="img-box">
      <slot></slot>
    </div>
    <h4 style="font-weight:300;">{{ title }}</h4>
  </div>
</template>

<script>
export default {
  props: ['title', 'icon'],
  data () {
    return {
    };
  },


  components: {},

  methods: {}
}

</script>
<style lang="scss" scoped>
.page-top {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 20px;
  .img-box {
    margin-right: 10px;
  }
}
</style>